<template>
	<div class="form">
		<el-form ref="form" :model="form" label-width="150rem" style="margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
			<el-form-item label="校区" prop="school_id">
				{{ form.school_name }}
			</el-form-item>
			<el-form-item label="档位名称" prop="rank_name">
				{{ form.rank_name }}
			</el-form-item>
			<el-form-item label="金额" prop="money">
				{{ form.money }}
			</el-form-item>
		</el-form>
		<div class="footer-button">
			<el-button @click="$router.back()">返回</el-button>
		</div>
	</div>
</template>
<script>

import {school} from "@/api/account-management";
import item from "@/views/finance/enrollment/payment-progress/components/Item.vue";

export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  computed: {
		item() {
			return item
		}
	},
	data() {
		return {
			form: {
				school_id: '',
				school_name: '',
				rank_name: '',
				money: '',
			},
		}
	},
	created() {
		this.$_register('api/recruit/common/consulting-school').then(res => {
			this.$_register.get('api/recruit/rank-tuition/show', {params: {id: this.$route.query.id}}).then(res_ => {
				this.form = res_.data.data
				this.form.school_name = res.data.data.entity_school.find(item => item.id === this.form.school_id).school_name
			})
		})

	},
	methods: {
		school,
		// 提交数据
		submit() {
			this.$refs.form.validate((res) => {
				if (res) {
					this.$_register.post("/api/recruit/rank-tuition/edit\n", this.form).then(res => {
						this.$message({
							type: 'success',
							message: res.data.msg,
							onClose: () => {
								this.$router.back();
							}
						});
					})
				}
			})
		},
	}
}
</script><style lang="scss" scoped>
.form {
	padding-top: 24rem;
}
</style>
